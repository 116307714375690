import Button from 'components/Button'
import useInvestments from 'hooks/useInvestments'
import AppLayout from 'layouts/AppLayout'
import { useEffect, useState } from 'react'
import InvestmentModal from '../components/InvestmentModal'
import ArrowBack from '../../../../static/images/icons/arrow-back.svg'
import { navigate } from 'gatsby'
import InvestmentDetailInfo from '../components/InvestmentDetailInfo'

import './InvestmentDetail.scss'
import makeTrackings from 'utils/make/trackings'
import { investmentsFormId } from 'utils/make/constants'

const InvestmentDetail = ({ location }) => {
  const { getInvestmentDetail } = useInvestments()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const investment = getInvestmentDetail(location?.state?.id)

  useEffect(() => {
    if (investment) {
      makeTrackings.InvestmentsElementViewed(investment.tracking)
    }
  }, [investment])

  if (!investment) return null

  return (
    <AppLayout useContentHeader={false} contentClassName="investment-detail">
      <div
        className="investment-detail__header"
        style={{ backgroundImage: `url("${investment.background}")` }}
      >
        <div className="investment-detail__header-content">
          <button
            className="investment-detail__back-button"
            onClick={() => {
              makeTrackings.formBacked({
                formId: investmentsFormId,
                formName: 'investment',
                stepId: 'a901e539-a383-40ae-8295-193192409556',
                stepName: 'investment-subproducts',
                stepNumber: 2,
              })

              navigate(-1)
            }}
          >
            <ArrowBack />
          </button>

          <img
            src={`/images/icons/${investment.icon}.svg`}
            className="investment-detail__header-icon"
            aria-hidden="true"
          />

          <h1 className="investment-detail__header-title">{investment.name}</h1>
          <p className="investment-detail__header-description">
            {investment.description}
          </p>
        </div>
      </div>

      <div className="investment-detail__content">
        <h2 className="investment-detail__content-title">
          {investment.howItWorks.title}
        </h2>

        {investment.howItWorks.infos.map((info, index) => (
          <InvestmentDetailInfo key={index} info={info} />
        ))}
      </div>

      <Button
        className="investment-detail__button"
        onClick={() => {
          makeTrackings.formContinued({
            formId: investmentsFormId,
            formName: 'investment',
            stepId: '6b116654-bf58-4712-96aa-018570d00f12',
            stepName: 'investment-subproducts',
            stepNumber: 2,
          })

          setIsModalOpen(true)
        }}
      >
        Comece a investir
      </Button>

      <InvestmentModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        selectedOption={investment.id}
      />
    </AppLayout>
  )
}

export default InvestmentDetail
