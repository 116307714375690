import './InvestmentDetailInfo.scss'

const InvestmentDetailInfo = ({ info }) => {
  return (
    <div className="investment-info">
      <img
        src="/images/icons/bullet.svg"
        aria-hidden="true"
        className="investment-info__bullet"
      />

      <div className="investment-info__content">
        <h3 className="investment-info__content-title">{info.title}</h3>
        <p className="investment-info__content-description">
          {info.description}
        </p>
      </div>
    </div>
  )
}

export default InvestmentDetailInfo
